@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: "Assistant", sans-serif;
  margin: 0;
}

.menu-page {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  direction: rtl;
  transition: background-color 0.3s;
}
#menu-main1 {
  width: 100%;
  padding: 0 1em;
  margin-top: 1rem;
}
.rest-pic-main img {
  width: 100%;
}
.dish-top {
  display: flex;
  justify-content: space-between;
}
.menu-cat h2 {
  color: #855e46;
  text-align: center;
  letter-spacing: 1px;
}
.rest-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
}
.line {
  height: 4px;

  width: 25px;
}
/* .hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0.7rem 0;
} */
.hamburger {
  width: 28px;
  height: 30px;
  cursor: pointer;
  user-select: none;
}
.hamburger div {
  width: 100%;
  height: 5px;
  margin: 4px auto;
  transition: transform 0.3s, opacity 0.3s;
  backface-visibility: hidden;
}
.on .line-one {
  transform: rotate(45deg) translate(5px, 5px);
}
.on .line-two {
  opacity: 0;
}
.on .line-three {
  transform: rotate(-45deg) translate(7px, -8px);
}

.dish-title {
  font-weight: 500;
  font-size: 1.3rem;
}
.dish-price {
  color: #855e46;
  font-size: 1.2rem;
}
.dish-desc {
  font-size: 1.1rem;
}
.menu-dish-item {
  margin-bottom: 0.9rem;
}
.menu-cat-cont {
  border: 1px solid #ccc;
  padding: 0 0.8rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.15);
}
#menu-main2 {
  width: 100%;
  padding: 0 1em;
  background-color: #0b0b0b;
}
#menu-main2 .menu-cat-cont {
  box-shadow: none;
  border: 0px;
}
div#menu-head-2 {
  background-color: #0b0b0b;
}
#menu-head-2 .rest-bar {
  margin: 1rem 0;
}
#menu-head-2 .rest-bar img {
  max-height: 33px;
}
#menu-head-2 .rest-bar .hamburger {
  margin: 0.1rem;
}
#menu-main2 .menu-cat h2 {
  color: #fff;
  text-align: right;
  font-weight: 300;
  border-bottom: 1px solid #cd0600;
  padding-bottom: 1rem;
}
#menu-main2 .dish-top,
#menu-main2 .dish-desc,
#menu-main2 .dish-price {
  color: white;
  font-weight: 100;
}
#menu-main2 .menu-dish-item {
  margin-bottom: 0.9rem;
  padding-top: 0.9rem;
}
.file-name span {
  border-top: 1px solid;
}
