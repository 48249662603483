/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Potta+One&display=swap"); */

/*
==============
     Grid
==============
  */
html {
  font-size: 17px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Assistant", sans-serif;
}
::-webkit-scrollbar {
  display: none;
}
::selection {
  color: crimson;
  background-color: #f499ac;
}
::placeholder {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Assistant", sans-serif;
  color: #ffc8d4;
  font-weight: 100;
}
a {
  text-decoration: none;
}
.app {
  color: rgb(11, 11, 11);
  display: grid;
  font-size: 1.2rem;
  grid-gap: 2px;
  grid-template-columns: repeat(12);
  grid-template-rows: 100px auto;
  grid-template-areas:
    "h h h h h h h h h h h h"
    "s s s s s s s s s s s s"
    "s s s s s s s s s s s s"
    "c c c c c c c c c c c c"
    "c c c c c c c c c c c c"
    "f f f f f f f f f f f f";
  margin: 0 auto;
  max-width: 1200px;
  justify-content: space-around;
  min-height: 100vh;
}

#header {
  grid-area: h;
  display: flex;
  /* direction: rtl; */
}
#show-case-home {
  grid-area: s;
  display: flex;
  direction: rtl;
  background-size: cover;
  /* position: relative; */
  background-position: center;
  /* height: calc(100vh - 218px); */
  /* max-width: 1200px; */
}
#show-case {
  grid-area: s;
  display: flex;
  direction: rtl;
  background-size: cover;
  /* position: relative; */
  background-position: center;
  /* height: calc(100vh - 218px); */
  /* overflow: hidden; */
}
#content {
  grid-area: c;
  display: flex;
  direction: rtl;
  /* height: calc(100vh - 218px); */
}
#footer {
  grid-area: f;
  display: flex;
  direction: rtl;
  width: 100%;
  margin-top: 2rem;
  /* position: absolute;
  bottom: 0; */
}
.bottom-content {
  display: none;
}
.show-home {
  padding: 90px 0;
}
.menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 2em;
}
.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.logo2 img {
  height: 50px;
}

.logCon {
  display: flex;
  align-items: center;
}
.logCon a {
  padding-left: 1.5em;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 1.2rem;
  color: #6a0053;
  transition: color 0.5s;
}
.logCon a:hover {
  font-weight: 500;
  color: #dc143c;
}
.logCon a:first-child {
  padding-right: 0;
}
.menuItems {
  display: flex;
  align-items: center;
}
a.item.reg {
  color: #7c27e9;
  padding: 0.3em 0.5rem;
  min-width: 80px;
  border: #7c27e9 1px solid;
  border-radius: 4px;
  margin-right: 1em;
  transition: background-color 0.5s linear, color 0.5s linear;
}
a.item.reg:hover {
  background-color: #6720c4;
  color: white;
}
a.item.in {
  color: crimson;
  border: crimson 1px solid;
  padding: 0.3em 0.5rem;
  min-width: 80px;
  border-radius: 4px;
  margin-right: 1em;
  transition: background-color 0.4s linear, color 0.5s linear;
}
a.item.in:hover {
  background-color: crimson;
  color: white;
}
.flexi {
  overflow: hidden;
  overflow-y: scroll;
}
.pointer-none {
  pointer-events: none;
}
.stop {
  cursor: not-allowed;
}
/*=======================================
------------    Show Case ----------------
=========================================*/
.main-pic {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 0 2em;
}
.main-show img {
  filter: brightness(1.05);
}
.main-show::after {
  content: "";
  display: block;
  position: absolute;
  background: #dc143c6e;
  top: 10%;
  z-index: -1;
  padding: 0.8em 1.3em;
  border-top-left-radius: 77%;
  border-top-right-radius: 35%;
  border-bottom-right-radius: 77%;
  border-bottom-left-radius: 35%;
  width: 100%;
  height: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  transform: translateX(-60%);
  animation: slidein forwards 1.5s;
  animation-timing-function: ease-in-out;
}
.main-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 2em;
  min-width: 50%;
  /* height: 60%; */
}
.main-title::after {
  content: "";
  display: block;
  position: absolute;
  background: #ff003305;
  top: 5px;
  z-index: -1;
  padding: 0.8em 1.3em;
  border-top-left-radius: 77%;
  border-top-right-radius: 35%;
  border-bottom-right-radius: 77%;
  border-bottom-left-radius: 35%;
  width: 100%;
  height: 100%;
  transform: translateX(110%);
  animation: slidein-right forwards 4.5s;
  animation-timing-function: ease-in-out;
}
.main-title p,
.title + p,
.about-us p {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 100;
}
.title {
  color: #6a0053;
  font-size: 2.5rem;
  font-weight: 400;
}
.mover {
  animation: mover 1s infinite alternate;
}
.main-title .red-btn {
  margin-top: 3rem;
}
.btn {
  outline: none;
  border: none;
  background-color: transparent;
  padding: 1rem;
}
.btn span {
  padding: 10px 25px;
  color: white;
  border-radius: 5px;
  transition: background 0.4s linear;
  letter-spacing: 1.5px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  border: none;
}
.red-btn span {
  padding: 10px 25px;
  color: white;
  border-radius: 5px;
  background: rgb(180, 25, 56);
  transition: background 0.4s linear;
  letter-spacing: 1px;
  cursor: pointer;
}
.red-btn span:hover {
  background: rgba(220, 20, 60, 1);
}
.blue span {
  background: rgb(66, 25, 180);
}
.blue span:hover {
  background: rgb(83, 38, 206);
}
.gray span {
  background: rgb(126, 120, 145);
}
.gray span:hover {
  background: rgb(152, 146, 172);
}
.green span {
  background: rgb(9, 146, 32);
}
.green span:hover {
  background: rgb(25, 180, 50);
}
button.btn.btn-outline.blue {
  color: #4219b4;
}
.reg-form .red-btn,
.complete-form .red-btn,
.forgot-form .red-btn,
.form-first-step .red-btn {
  margin: 1rem 1rem 1rem 0;
  align-self: flex-end;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 1rem;
}
.complete-form .red-btn {
  padding: 1rem;
}
.form-first-step .red-btn {
  align-self: flex-start;
  margin: 1rem 0 1rem 1rem;
}
.modal-inner .btn.green {
  margin-top: 1rem;
}

.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 85%;
}

h3.title-about {
  font-size: 3rem;
  margin: 0 auto;
  color: crimson;
  letter-spacing: 2px;
}

.chef img {
  max-width: 100%;
}
h4.title {
  margin-top: 1rem;
}
/* ======================================
-------  Form Confirm -------------------
=======================================*/
form.rigistration,
form.complition {
  display: flex;
  flex-direction: column;
}
span.or {
  font-size: 1.2rem;
  text-align: center;
}
.rigistration .google-login {
  border: 1px solid crimson;
  border-radius: 8px;
  overflow: hidden;
}
.modal input:focus {
  outline: none;
  border: 1px solid #ca5fb3;
  color: #6a0053;
}
.complete-form,
.forgot-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  direction: rtl;
  min-width: 95%;
}

.complete-form::after,
.forgot-form::after {
  content: "";
  display: block;
  position: absolute;
  background: #dc143c6e;
  top: 110px;
  z-index: -1;
  padding: 0.8em 1.3em;
  border-top-left-radius: 77%;
  border-top-right-radius: 35%;
  border-bottom-right-radius: 77%;
  border-bottom-left-radius: 35%;
  width: 100%;
  height: 70%;
  box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px,
    rgb(0 0 0 / 30%) 0px 18px 36px -18px;
  transform: translateX(-60%);
  animation: slidein forwards 1.5s;
  animation-timing-function: ease-in-out;
}
.slaid-in {
  transform: translateX(60%);
  animation: slidein forwards 0.7s;
  animation-timing-function: ease-in-out;
}
.fade-in {
  animation: fadeIn ease 1.5s;
}
.shake:hover {
  animation: shake 2.2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@keyframes shake {
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(6px);
  }
}
@keyframes slidein {
  100% {
    transform: translateX(0);
  }
}
@keyframes slidein-right {
  100% {
    transform: translateX(50%);
  }
}
/*=======================================
------------   Content     ----------------
=========================================*/
.top-content {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.flag {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}
.flag span {
  align-self: center;
  padding: 0.3rem 1rem;
  background: rgb(180, 25, 56);
  background: linear-gradient(
    0deg,
    rgba(180, 25, 56, 1) 0%,
    rgba(220, 20, 60, 1) 100%
  );
  color: white;
  border-radius: 50vw;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  font-size: 1.1rem;
  letter-spacing: 1px;
}
.text-center {
  text-align: center;
}
.boxes {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
}
.box {
  margin: 1rem;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
  flex: 1 1 auto;
  min-width: 30%;
}
.box p {
  font-size: 1.3rem;
  letter-spacing: 1px;
}
.box:nth-child(2) {
  background: rgb(180, 25, 56);
  background: linear-gradient(
    0deg,
    rgb(117, 16, 36) 0%,
    rgba(220, 20, 60, 1) 100%
  );
  color: lavenderblush;
}
.box-content h3 {
  color: #ca5fb3;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
}
.box:nth-child(2) h3 {
  color: #fff;
}
.box img {
  width: 100px;
  padding: 1rem;
  background-color: lavenderblush;
  border-radius: 5px;
}
/*=======================================
------------   Footer     ----------------
=========================================*/

.footer-main {
  border-top: 1px solid #6a0053;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
}
/*

=======  Modal =========

*/

.modal-pop {
  opacity: 1;
  color: #436099;
  position: absolute;
  z-index: 1001;
  text-align: right;
  background: lightblue;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  position: relative;
  margin: 1.75rem auto;
  display: flex;
  flex-direction: column;
  background: #b41938;
  padding: 2rem;
  border-top-left-radius: 33%;
  border-top-right-radius: 77%;
  border-bottom-right-radius: 33%;
  border-bottom-left-radius: 77%;
  width: 85%;
}
.modal-inner {
  border-top-left-radius: 77%;
  border-top-right-radius: 35%;
  border-bottom-right-radius: 77%;
  border-bottom-left-radius: 35%;
  background: white;
  direction: rtl;
  padding: 0 3rem 5rem 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.modal-inner-header,
.inner-header {
  margin: 0 0 0.5rem 0;
  color: #6a0053;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
}
.small-header {
  font-size: 2rem;
}
.modal label,
.modal input,
.complete-form label,
.complete-form input,
.forgot-form label,
.forgot-form input,
.form-first-step label,
.form-first-step input,
textarea.inputCenter,
.cat-input,
.ffild,
.ffild input,
.today-form label,
.today-form input {
  font-size: 1.5rem;
  font-weight: 100;
  font-family: inherit;
  width: 100%;
}
.ffild {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 360px;
  margin: 1rem;
}
.settings-disabled {
  flex: 1;
  justify-content: space-between;
}
.equal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-close-button {
  outline: none;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.4rem;
  padding: 15px 20px;
  color: crimson;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: pink;
}
.modal-close-button:hover,
.spin:hover {
  animation-name: spin;
  animation-duration: 350ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
.plus.spin {
  line-height: 1;
}
.modal-cat {
  color: #436099;
  font-size: 1.3rem;
  padding: 0.5em;
  letter-spacing: 1px;
  display: flex;
  border-radius: 3px;
  direction: rtl;
  text-decoration: underline;
}
.modal-qr {
  font-size: 1.3rem;
  padding: 0 0.5rem 0.5rem 0.5rem;
  letter-spacing: 1px;
}

.flex1 {
  flex: 1;
}

/* ======================= 

         Log In

=========================*/

.google-login,
.small-header {
  margin-top: 2rem;
}
button.google {
  align-items: center;
  background: white;
  border: 0;
  outline: 0;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  color: crimson;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1.2rem;
}
.google img {
  height: 1.2rem;
}
/*==========================

--------   Panel -----------

============================*/

.panel {
  flex-direction: column;
  padding: 0 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  border: 2px solid crimson;
  border-radius: 8px;
  font-weight: 300;
}
.show-home-edit .panel {
  width: 48%;
}
.panel h2 {
  font-weight: 300;
}
.header-info {
  border-bottom: 1px solid #f6c1eb;
}
.header-info span {
  font-weight: 400;
}
.input-center {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
form.form-first-step {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
h3.cat-title {
  font-weight: 400;
  font-size: 1.5rem;
  color: #6a0053;
  border-bottom: 1px solid #f6c1eb;
}

form.addForm {
  display: flex;
  margin: 1.5rem 0 1.5rem 1rem;
  transform: translateX(60%);
  align-items: center;
  transition: transform 0.3s linear;
}
form.addForm.show {
  display: flex;
  margin: 1.5rem 0 1.5rem 1rem;
  transform: translateX(0);
}
input#category[type="text"]:focus {
  color: #6a0053;
  border-color: #7c27e9;
  border-radius: 5px;
  outline: none;
}
.buttons-row {
  margin-top: 1.3rem;
}
.catItemContainet {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #646464;
}
.catItem {
  display: flex;
  margin: 1rem 0.5rem;
  padding: 0.8rem 0;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.inputCenter {
  flex-direction: column;
  display: flex;
}
.catName {
  display: inline-block;
  color: rgb(168 24 58);
  font-weight: 500;
  cursor: pointer;
  font-size: 1.4rem;
  letter-spacing: 2px;
  margin: 0;
}
.file-name {
  margin-bottom: 1rem;
}
.showMain {
  margin-top: 1rem;
}
.left img {
  height: 1.2rem;
}
span.price {
  font-size: 1.3rem;
  color: green;
  font-weight: 600;
}
.trash,
.pencil {
  cursor: pointer;
  height: 1.2rem;
  padding-left: 1rem;
}
.pencil {
  padding-right: 1rem;
}
.cat-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cat-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
}
.inner-cat {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
}
.cat-head {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-right: 1.5rem;
}

h3.catName {
  padding-left: 1rem;
}
.plus {
  height: 22px;
  margin-right: 0.5rem;
  cursor: pointer;
}
.login-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 7rem;
}
.dish-right {
  display: flex;
  align-items: center;
  width: 100%;
}
.dish-list {
  flex: 1;
  min-width: 95%;
}
.dish-name {
  cursor: pointer;
}
.dish-name span {
  font-weight: 700;
}
.trash-con {
  padding: 5px 15px 0 0;
}
.qrCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qr-title {
  color: #fff;
  font-size: 1.5rem;
}
.dots {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  transform: rotate(45deg);
}
.dots img {
  opacity: 0.5;
  height: 1.4rem;
}
.rotate {
  transform: rotate(45deg);
  padding-left: 0;
}
.info .rotate {
  cursor: pointer;
  padding: 1px;
}
.settings-disabled {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.settings-disabled input {
  cursor: pointer;
}
.btnGroup {
  margin: 1.5rem;
  display: flex;
  justify-content: center;
}
.cat-title span {
  color: #6a0053;
}
h3.cat-title {
  padding-bottom: 1rem;
  color: #000;
}
/* ========================
--------   Edit Page --------------------
====================================== */

/* The device with borders */
.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 16px #f4f4f4 solid;
  border-top-width: 20px;
  border-bottom-width: 20px;
  border-radius: 36px;
  overflow-y: auto;
  box-shadow: rgba(193, 193, 223, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

div#menu-footer1 {
  height: 2.5rem;
  background-color: rgb(201, 140, 103);
}
div#menu-footer {
  height: 2.5rem;
  background-color: crimson;
}
#menu-footer,
#menu-footer1 {
  color: #ddd;
  text-align: center;
}
.main-panel-edit h2 {
  text-decoration: underline;
}
button.btn.btn-outline {
  border: 2px solid;
  padding: 0 0.5rem;
  border-radius: 6px;
  letter-spacing: 1.5px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  transition: color 0.3s linear;
}
button.btn.btn-outline:hover {
  color: #607d8b;
}
.pink span {
  background: hotpink;
}
.pink span:hover {
  background: #fd9fce;
}
.coral span {
  background: coral;
}
.coral span:hover {
  background: rgb(255, 151, 114);
}

.main-image-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
form.upload-form {
  font-size: 1.1rem;
}
button.btn.btn-outline.pink,
button.btn.btn-outline.pink span {
  color: coral;
}
.img-int-cont {
  width: 200px;
  height: 50px;
  margin: 0.5rem auto;
  overflow: hidden;
  background: transparent;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.log-change {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.logo-lable span {
  font-weight: 500;
}
.pick-a-theme {
  display: flex;
  margin-bottom: 1rem;
  /* height: 100%; */
}

.smartphone.small-phone {
  width: 120px;
  border: 8px #f4f4f4 solid;
  height: 213px;
  border-radius: 14px;
  cursor: pointer;
  transition: box-shadow 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.smartphone.small-phone.dark {
  background: rgb(46, 47, 48);
  background: linear-gradient(
    0deg,
    rgba(46, 47, 48, 1) 0%,
    rgba(70, 76, 81, 1) 100%
  );
}
.smartphone.small-phone.light {
  background: rgb(231, 237, 246);
  background: linear-gradient(
    0deg,
    rgba(231, 237, 246, 1) 0%,
    rgba(235, 255, 249, 0.93) 100%
  );
}
.smartphone.small-phone.color {
  background: rgb(131, 58, 180);
  background: linear-gradient(
    0deg,
    rgba(131, 58, 180, 0.5189426112241772) 0%,
    rgba(253, 29, 29, 0.5161414907759979) 50%,
    rgba(252, 176, 69, 0.50773812943146) 100%
  );
  /* pointer-events: none; */
}
.color .checkmark-dis {
  position: absolute;
  text-align: center;
  color: transparent;
  height: 100%;
}
.color .checkmark-dis:hover {
  text-align: center;
  color: #474747;
}
.radio-cont {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-cont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
}

/* When the radio button is checked, add a blue background */
.radio-cont input:checked ~ .checkmark {
  /* background-color: rgba(20, 220, 30, 0.103); */
  width: 100%;
  height: 100%;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-cont input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-cont .checkmark:after {
  top: 50%;
  left: 50%;
  width: 15px;
  height: 24px;
  border: solid #4caf50;
  border-width: 0 8px 8px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}
.theme-name {
  padding: 0 1rem 1rem 0;
  margin: 0;
}
.panel h3.cat-title {
  margin: 1rem 0;
  padding-bottom: 0.5rem;
}
form.colors-form {
  display: flex;
  justify-content: space-around;
  margin: 2rem 1rem;
}
.colors-lable {
  font-size: 1.1rem;
  font-weight: 500;
}
label.colors-lable {
  padding-left: 1rem;
}
.theme-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button.btn.btn-outline.red {
  color: crimson;
  animation: blink infinite 1.3s;
}
button.btn.btn-outline.green {
  color: rgb(9, 146, 32);
  animation: blink infinite 1.2s;
  padding: 0.5rem 1rem;
}
.expliner {
  font-size: 1.3rem;
  color: darkorange;
  animation: blink infinite 1.5s;
}
.apear {
  animation: apear forwards 5s;
}
.disapear {
  display: none;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes apear {
  0% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250%);
  }
}

.small {
  padding: 0 0.5rem;
}
/*=====================================

-------       Today's Special   --------

========================================*/
form.today-form {
  margin: 1rem 0;
}
.main-edit {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.offer {
  animation: slideleft forwards 1.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-delay: 4s;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px,
    rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px,
    rgb(0 0 0 / 9%) 0px -3px 5px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  align-items: center;
  height: auto;
  width: 98%;
  padding-bottom: 1.5rem;
  text-align: center;
  transform: translateX(120%);
  position: sticky;
  bottom: 0;
  left: 1%;
}
.offer-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
}
.offer-main {
  font-size: 1.1rem;
  letter-spacing: 1px;
  font-weight: 400;
}
.today-form label {
  font-weight: 400;
}
.close-offer {
  font-size: 1.2rem;
  padding: 0.2rem 1.5rem;
  /* border-radius: 2px; */
  cursor: pointer;
  background: crimson;
  width: 100%;
  color: white;
  letter-spacing: 1px;
  position: absolute;
  bottom: 0;
}

.no-shadow {
  box-shadow: none;
}
.input-cont {
  margin-top: 1rem;
}
@keyframes slideup {
  100% {
    transform: translateY(0);
  }
}
@keyframes slideleft {
  100% {
    transform: translateX(0);
  }
}
@keyframes slidedown {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(130%);
    opacity: 0;
    display: none;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: crimson;
}

/* input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
} */

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.turn-on-off {
  margin-bottom: 1rem;
}
.add-theme {
  text-align: center;
}
.add-theme .add-theme-title {
  font-size: 1.2rem;
  color: #6a0053;
  margin: 2rem 0;
}
.hide-offer {
  animation: slidedown forwards 1.5s ease-in-out;
}
/*====================================
--------   Media Queries  ------------
=====================================*/
@media (max-width: 1199px) {
  .app {
    /* height: 100%; */
    max-width: 100%;
    overflow-x: hidden;
  }
  /* #show-case,
  #show-case-home {
    height: 100%;
  } */
}
@media (max-width: 769px) {
  /* #show-case,
  #show-case-home {
    height: calc(100vh - 229px);
  } */
  .smartphone.small-phone {
    width: 80px;
    border: 8px #f4f4f4 solid;
    height: 107px;
    border-radius: 14px;
    cursor: pointer;
    transition: box-shadow 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}
@media (max-width: 639px) {
  .app {
    color: rgb(11, 11, 11);
    display: flex;
    flex-direction: column;
  }
  .bottom-content {
    display: flex;
    width: 100%;
    padding: 0 1rem;
    text-align: center;
    border: 1px solid crimson;
    font-size: 1.3rem;
    color: #ca5fb3;
  }
  .show-home {
    padding: 1rem 0;
  }
  .main-show,
  .menuItems,
  .main-title .red-btn,
  .about-nav {
    display: none;
  }
  .main-pic,
  .top-content {
    padding: 0 1rem;
  }
  .main-title {
    padding: 0;
  }

  .title {
    margin-top: 0;
    text-align: center;
  }
  .menu {
    display: block;
  }
  .logCon {
    justify-content: center;
    margin: 1rem;
  }
  .boxes {
    flex-direction: column;
  }
  .flag span {
    margin-bottom: 1rem;
  }
  .main-title::after {
    background: #ff00331f;
    animation: slidein-right forwards 2.5s;
  }
  .title-p {
    text-align: center;
  }
}
.Toastify {
  text-align: right;
}
