.loader {
  margin: auto;
  border: 8px solid #bdc3c7;
  width: 60px;
  height: 60px;
  border-top-color: #1abc9c;
  border-bottom-color: #3498db;
  border-radius: 50%;
  animation: coloredspin 3.5s linear infinite;
}

@keyframes coloredspin {
  0% {
    transform: rotate(0deg) scale(1);
    border-top-color: #1abc9c;
    border-bottom-color: #1abc9c;
  }
  25% {
    border-top-color: #cc2e9d;
    border-bottom-color: #cc2ecc;
  }
  50% {
    transform: rotate(360deg) scale(1.05);
    border-top-color: #db343c;
    border-bottom-color: #ce0e18;
  }
  75% {
    border-top-color: #9619c7;
    border-bottom-color: #9b59b6;
  }
  100% {
    transform: rotate(720deg) scale(1);
    border-top-color: #1abc9c;
    border-bottom-color: #1abc9c;
  }
}
