/*========================
------   Hamburger ------
==========================*/
.toggle-button {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 35px;
  width: 44px;
  background: transparent;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
}
.toggle-button:focus {
  outline: none;
}
.toggle-button-line {
  width: 41px;
  height: 5px;
  background: rgb(242 36 119);
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
/* @media only screen and (min-width: 768px) {
  .toggle-button {
    display: none;
  }
} */

/*========================
------   SideDrawer ------
==========================*/
.side-drawer {
  background: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 1px 0px 10px rgb(0 0 0 / 30%);
  direction: rtl;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  max-width: 400px;
  overflow: hidden;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 199;
}
.side-drawer.open {
  transform: translateX(0);
}
.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 0;
}
.side-drawer li {
  padding: 1.5rem;
  display: flex;
  align-items: flex-end;
  transition: background 0.3s ease-in-out;
}

.side-drawer a {
  color: #a8183a;
  font-size: 1.4rem;
  transition: 0.8s;
  font-weight: 500;
  letter-spacing: 1px;
}
.side-drawer a:hover,
.side-drawer a:active {
  color: #eb5a7b;
  font-size: 1.4rem;
}

/* @media only screen and (min-width: 768px) {
  .fa,
  .art::before {
    display: none;
  }
} */
.side-drawer img {
  height: 1.5rem;
}
.design {
  transform: rotate(180deg);
  transition: transform 0.4s linear;
}
.design:hover {
  transform: rotate(170deg);
}
.design + span,
.link-drawer + span {
  padding-right: 1.5rem;
}
.top-drawer {
  background: #ff3c5e;
  color: white;
  text-align: center;
  letter-spacing: 2px;
}
.side-drawer li:hover {
  background: antiquewhite;
}
